<template>
  <div>
    <div class="errorLayout">
      <div class="errorLayoutSplitter">
        <img src="@/assets/icons/errorLatest.png" alt="" class="errorPoster" />
        <p class="errorText">Error 404 !</p>
        <p class="errorDescription">
          We can't find the page you are looking for.
        </p>
        <button class="error-return" @click="goToHome()">Return To Home</button>
      </div>

      <!-- <p class="no-contents">{{$t("No Contents Found")}}</p> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorPage",
  data() {
    return {};
  },
  methods:{
    goToHome(){
      console.log('go to home----------------')
      this.$router.push({ name: "Home" });
    }
  }
};
</script>
<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./Error404.scss"
</style>
